import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"

import host from "./host";
import Cookies from "universal-cookie";


const cookies = new Cookies();

class Login extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: []
        };

    }

    submit() {
        let name = document.getElementById('userName').value;
        let password = document.getElementById('password').value;
        console.log(name, password);
        axios.post(host + 'users/auth/login', {
            phone: name,
            password: password
        })
            .then(function (response) {
                window.location.href = '/'
                cookies.set("token", response.data.data.token, {
                    path: "/",
                    expires: new Date(Date.now() + 604800000)
                });
            })
            .catch(function (error) {
                window.alert('خطأ في رقم الهاتف او كلمة المرور')
            });
    }

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div class="limiter">
                                    <div class="container-login100" style={{ backgroundImage: "url('images/bg-01.jpg')" }}
                                    >
                                        <div class="wrap-login100 p-t-30 p-b-50">
                                            <span class="login100-form-title p-b-41">
                                                تسجيل دخول
                                            </span>
                                            <div class="login100-form validate-form p-b-33 p-t-5">

                                                <div class="wrap-input100 validate-input" data-validate="Enter username">
                                                    <input class="input100" type="text" id="userName" name="username" placeholder="User name" />
                                                    <span class="focus-input100" ></span>
                                                </div>

                                                <div class="wrap-input100 validate-input" data-validate="Enter password">
                                                    <input class="input100" type="password" id="password" name="pass" placeholder="Password" />
                                                    <span class="focus-input100" data-placeholder="&#xe80f;"></span>
                                                </div>

                                                <div class="container-login100-form-btn m-t-32">
                                                    <button class="login100-form-btn" onClick={() => {
                                                        this.submit()
                                                    }}>
                                                        دخول
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Login;