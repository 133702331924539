import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, Textarea, Label } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft, } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

import { FcMissedCall, FcApproval } from "react-icons/fc";

import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "delete", name: "الغاء", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "تأكيد", options: { width: 80, filter: true, sort: true, } },


    { field: "more", name: "معلومات", options: { width: 80, filter: true, sort: true, } },
    { field: "whatsapp", name: "واتساب", options: { filter: true, sort: true, } },
    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            spinPage: true,
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/all/ordrers/my?status=-1`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.orders.length; i++) {

                    let newPhone = res.data.data.orders[i].phone.slice(1);

                    let obj = {
                        id: res.data.data.orders[i].id,
                        phone: <a href={`tel:${res.data.data.orders[i].phone}`}>{res.data.data.orders[i].phone}</a>
                        ,
                        whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                        page: res.data.data.orders[i].page_name,
                        address: <Component initialState={{ isShown: false, items: [], notes: "", spin: true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}
                                        confirmLabel="جعله لايرد"
                                        intent="Warning"
                                        hasFooter={true}
                                        title={"تحديد الطلب لايرد"}
                                        onConfirm={() => {
                                            axios({
                                                method: 'post',
                                                url: host + 'users/web/orders/confirm',
                                                headers: { 'Authorization': cookies.get("token") },
                                                data: {
                                                    status: 3,
                                                    id: res.data.data.orders[i].id,
                                                }
                                            }).then((response) => {
                                                window.alert(`تم تحديد كـ لايرد الطلب رقم ${res.data.data.orders[i].id} الطلب بأسم ${res.data.data.orders[i].name}`)
                                                setState({ isShown: false, notes: "" })
                                                this.componentDidMount()
                                            }).catch((error) => {
                                                setState({ isShown: false, notes: "" })
                                                window.location.href = '/Error'
                                            })



                                        }}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                            {!state.spin ?
                                                <div>
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الوصل</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم الزبون</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={80}>

                                                            <Table.Row >
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].id}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].name}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].government + " / " + res.data.data.orders[i].city}</div></Table.TextCell>

                                                            </Table.Row>

                                                        </Table.Body>
                                                    </Table>

                                                </div>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <FcMissedCall onClick={() => {
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', fontSize: 25 }} />
                                </Pane>
                            )}
                        </Component>,
                        more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}
                                        hasFooter={false}
                                        title={res.data.data.orders[i].name}
                                        onConfirm={() => { setState({ isShown: false }) }}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                            {!state.spin ?
                                                <div>
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الوصل</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم البيج</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر الكلي</div></Table.TextHeaderCell>


                                                        </Table.Head>
                                                        <Table.Body height={100}>

                                                            <Table.Row >
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].id}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].page_name}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].price}</div></Table.TextCell>

                                                            </Table.Row>

                                                        </Table.Body>
                                                    </Table>
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المادة</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                        </Table.Head>
                                                        <Table.Body height={100}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>

                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>


                                                        </Table.Head>
                                                        <Table.Body height={100}>

                                                            <Table.Row >
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].government + " / " + res.data.data.orders[i].city}</div></Table.TextCell>

                                                            </Table.Row>

                                                        </Table.Body>
                                                    </Table>

                                                </div>

                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <FaListUl onClick={() => {
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                </Pane>
                            )}
                        </Component>,
                        profile: <Component initialState={{ isShown: false, items: [], notes: "", spin: true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}
                                        confirmLabel="تأكيد الطلب"
                                        intent="success"
                                        hasFooter={true}
                                        title={"تأكيد الطلب"}
                                        onConfirm={() => {


                                            axios({
                                                method: 'post',
                                                url: host + 'users/web/orders/confirm',
                                                headers: { 'Authorization': cookies.get("token") },
                                                data: {
                                                    status: 1,

                                                    notes: state.notes,
                                                    id: res.data.data.orders[i].id,


                                                }
                                            }).then((response) => {
                                                window.alert(`تم تأكيد الطلب رقم ${res.data.data.orders[i].id} الطلب بأسم ${res.data.data.orders[i].name}`)
                                                setState({ isShown: false, notes: "" })
                                                this.componentDidMount()
                                            }).catch((error) => {
                                                setState({ isShown: false, notes: "" })
                                                window.location.href = '/Error'
                                            })



                                        }}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                            {!state.spin ?
                                                <div>
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الوصل</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم الزبون</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={80}>

                                                            <Table.Row >
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].id}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].name}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].government + " / " + res.data.data.orders[i].city}</div></Table.TextCell>

                                                            </Table.Row>

                                                        </Table.Body>
                                                    </Table>
                                                    <br></br>
                                                    <Label >ملاحظات</Label>
                                                    <Textarea id="notes" value={state.notes} name="textarea-1" placeholder="المحلاحظات ان وجدت"
                                                        onChange={(e) => {

                                                            setState({ notes: e.target.value })
                                                        }} />
                                                </div>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <FcApproval onClick={() => {
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', color: '#4a7fc1', fontSize: 25 }} />
                                </Pane>
                            )}
                        </Component>,
                        delete: 
                        <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="الغاء الطلب "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel='الغاء'
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            axios({
                                                method: 'post',
                                                url: host + 'users/web/orders/confirm',
                                                headers: { 'Authorization': cookies.get("token") },
                                                data: {
                                                    status: 2,

                                                    notes: state.notes,
                                                    id: res.data.data.orders[i].id,


                                                }
                                            }).then((response) => {
                                                window.alert(`تم الغاء الطلب رقم ${res.data.data.orders[i].id} الطلب بأسم ${res.data.data.orders[i].name}`)
                                                setState({ isShown: false, notes: "" })
                                                this.componentDidMount()
                                            }).catch((error) => {
                                                setState({ isShown: false, notes: "" })
                                                window.location.href = '/Error'
                                            })

                                        }}
                                    >
                                        <span id='msgDelete'>هل انت متاكد من عملية الالغاء</span>
                                    </Dialog>
                                    <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {
                window.alert('تم الالغاء')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>قائمة الطلبات الـ لايرد</h4>
                                </div>


                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;