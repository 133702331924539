
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Context from "./context";
import SubmitForm from "./SubmitForm";
import Login from "./login";
import Home from "./home";
import Orders from "./orders";
import Orders2 from "./orders2";
import Items from "./items";
import Done from './done';
import PrintTable from './printTable';
import TableView from './TableView';
import Search from './search';
import Order from './orderById';

import Check2 from './check2';
import Edit from './edit';
import Print from './print';
import Error from './error';
import Contact from './Contact';
import './App.css'
import Miss from './Miss';
import Miss2 from './miss2';

class App extends Component {
  constructor() {
    super();
    this.state = {

    }

  }
  render() {
    return (
      <BrowserRouter >
        <Context.Provider
          value={
            {
              value: this.state,
              actions: {
              }
            }
          }
        >

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/From" component={SubmitForm} />
            <Route path="/login" component={Login} />
            <Route path="/Orders" component={Orders} />
            <Route path="/Orders2" component={Orders2} />
            <Route path="/Items" component={Items} />
            <Route path="/Done" component={Done} />
            <Route path="/edit" component={Edit} />

            {/* <Route path="/Print" component={PrintTable} /> */}
            <Route path="/TableView" component={TableView} />
            <Route path="/Contact" component={Contact} />
            <Route path="/search" component={Search} />
            <Route path="/error" component={Error} />

            <Route path="/tesst" component={Error} />
            <Route path="/check" component={Check2} />
            <Route path="/check2" component={Check2} />
            <Route path="/miss" component={Miss2} />
            <Route path="/miss2" component={Miss2} />
            <Route path="/order" component={Order} />
            <Route path="/Print2/:id" component={Print} />
          </Switch>

        </Context.Provider>
      </BrowserRouter>
    );
  }
}

export default App;




